<template>
	<div id="systenUpdate">
		<template v-if="!show.histroy">
			<div class="el-content" v-if="is_update == 1">
				<div class="update_data" style="margin-left: 35px;">
					<div class="update_data_title">
						<span>最新版本：{{ updateInfo.version }}</span>
						<a-tag color="#FF6633" v-if="updateInfo.is_dev">开发版</a-tag>
						<a-tag color="#00CC66" v-else>正式版</a-tag>
					</div>
					<div style="color: #999;font-size: 12px;margin-bottom: 8px;">提示：暂不推荐更新开发版，请酌情更新！</div>
					<div class="update_data_desc">
						<div>版本描述</div>
						<div v-html="enter(updateInfo.version_detail)"></div>
					</div>
				</div>
				<a-form ref="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
					<a-form-item label="更新协议事项">
						<a-checkbox-group v-model:value="checkAgreement">
							<div><a-checkbox value="1">确保您的系统文件官方文件保持一致，避免被非法篡改，远离盗版</a-checkbox></div>
							<a-checkbox value="2">确保您已经做好了相关文件的备份工作，认同官方的更新行为并自愿承担更新所存在的风险</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
					<a-form-item>
						<a-space>
							<a-button v-has="{ action: 'sass_check_update' }" type="primary" @click="checkUpdate">检查更新</a-button>
							<a-button v-has="{ action: 'sass_site_update' }" type="primary" @click="systemUpdate">立即更新</a-button>
							<a-button type="primary" @click="siteAuth" v-if="is_auth">站点授权</a-button>
							<a-button type="primary" @click="getHistoryLog">获取历史更新日志</a-button>
						</a-space>
					</a-form-item>
				</a-form>
			</div>

			<div class="el-content" v-if="is_update == 3">
				<div class="updatEdition">
					<div><img src="https://kundian.cqkundian.com/farm/20190827/c3781756423319aaa0aacd9a97dd6cb31566876722" alt="" /></div>
					<div>系统当前版本：{{ versionInfo.version }}</div>
					<div>{{ versionInfo.update_time }}</div>
					<div>服务到期时间：{{ versionInfo.expire_time }}</div>
					<div>当前版本为最新版本，您可以点击此按钮，立即检查是否有新版本。</div>
					<a-space>
						<a-button v-has="{ action: 'sass_check_update' }" type="primary" @click="checkUpdate">检查更新</a-button>
						<a-button type="primary" @click="siteAuth" v-if="is_auth">站点授权</a-button>
						<a-button type="primary" @click="getHistoryLog">获取历史更新日志</a-button>
					</a-space>
				</div>
			</div>

			<div class="el-content" v-if="is_update == 2">
				<div class="updatelist_title">更新进度</div>
				<div class="updatelist_progress">
					<a-progress :percent="percentage" />
				</div>
				<div class="updatelist_list">
					<div class="updatelist_list_title">更新日志</div>
					<div class="updatelist_list_cont" id="dialogue_box">
						<div v-for="(item, index) in updatelist" :key="index">
							<div>{{ item }}</div>
						</div>
					</div>
				</div>
				<a-button @click="goPage('https://www.yuque.com/docs/share/69047dcb-d7e7-4a51-9c8a-73498937618d?# ')">更新遇到问题？</a-button>
				<a-button plain @click="is_update = 1">返回</a-button>
			</div>

			<div class="el-content updatesuscss" v-if="is_update == 4">
				<div class="suscss_title ri-check-line ri-3x"></div>
				<div class="suscss_tips">版本升级成功</div>
				<div class="suscss_desc">系统版本已经升级到{{ updateInfo.version }}</div>
				<div class="suscss_suscsstips">系统更新完成，请按Ctrl+F5刷新浏览器缓存后访问！</div>
				<router-link to="/admin/index">
					<a-button type="primary">返回首页</a-button>
				</router-link>
			</div>
		</template>

		<!-- 更新历史版本日志 -->
		<div class="el-content" v-if="show.histroy">
			<a-button @click="show.histroy = false" style="margin-left: 18px;margin-bottom: 18px;"><i class="ri-arrow-left-s-line ri-top"></i>返回</a-button>
			<a-timeline>
				<a-timeline-item v-for="(item, index) in histroyList" :key="index">
					<a-card>
						<h3>{{ item.file_domain }} {{ item.version_desc }} <span class="f12">更新时间：{{ item.create_time }}</span></h3>
						<div class="history_data_desc"><div v-html="enter(item.version_detail)"></div></div>
					</a-card>
				</a-timeline-item>
			</a-timeline>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import tool from '@/util/tool';
import { $post } from '@/api/http';
import { useRoute } from 'vue-router';
import commonModel from '@/api/common';
import programModel from '@/api/saas/app';
import loginModel from '@/api/login';
import { Modal } from 'ant-design-vue';
export default {
	setup(){
		const state = reactive({
			updateInfo: [],
			versionInfo: [],
			is_auth: false,
			checkAgreement: [],
			form: {},
			is_update: 3, //1=新版本 2=更新中 3=最新版本 4=升级成功 5=历史版本查看
			percentage: 10,
			updatelist: [],
			histroyList: [], //历史更新日志
			show: {
				histroy: false
			}
		})

		const opt = useRoute().query
		if( opt.histroy ) getHistoryLog()

		checkUpdate()
		checkSite()
		scrollToBottom()

		function checkUpdate(){
			saasSetModel.getCurrentVersinInfo(true,res=>{
				state.versionInfo = res.site
				state.updateInfo = res.new
				if( res.is_update == 1 ) state.is_update = 1
				if( res.update_status == 'ING' ) {
					state.is_update == 2
					getSysUpdateLog()
				}
			})
		}

		function systemUpdate(){
			let { updateInfo, checkAgreement } =  state
			if( checkAgreement.length !=2 ) {
				tool.message("请先同意并勾选更新协议！","warning")
				return
			}

			if( !updateInfo.version ){
				tool.message("请先检查是否存在新版本！","warning")
				return
			}
			state.is_update = 2
			setSysUpdate()
		}

		function setSysUpdate(){
			$post("setSysUpdate",{},true,1).then(()=>{
				state.is_update = 2
				getSysUpdateLog()
			})
		}

		function getSysUpdateLog(){
			$post("getSysUpdateLog",{},true,1,false).then(res=>{
				let d = res.data

				state.updatelist = d.logs
				state.percentage = d.speed
				if( d.status == 'ING') getSysUpdateLog()
				if( d.status == 'SUCCESS') {
					celSysUpdate()
					state.is_update = 4
				}

				if( d.status == 'FAIL'){
					celSysUpdate()
					tool.message("更新失败，请重新更新！","error")
				}
			})
		}

		//更新完成
		function celSysUpdate(){
			$post("celSysUpdate",{},true,1,false).then(()=>{
				programModel.cloudRefreshCache()
				commonModel.clearCache()
				Modal.info({
					title: '更新已完成，需要退出重新登录~',
					onOk() {
						loginModel.logout(false)
					}
				})
			})
		}

		function checkSite(){
			$post("checkSite",{},true,1).then(res=> {
                state.is_auth = res.errorCode === 1
            }).catch((res)=>{
                state.is_auth = res.errorCode === 1
            })
		}

		function siteAuth(){
			$post("updateAuthorization",{},true,1).then(res=>tool.message(res.data.message))
		}

		function enter(str){
			return str.replace(/\n/g, '<br/>')
		}

		function scrollToBottom() {
			if (state.is_update == 2) {
				let div = document.getElementById('dialogue_box');
				div.scrollTop = div.scrollHeight;
			}
		}

		function goPage(url) {
			window.open(url, '_blank');
		}

		function getHistoryLog() {
			commonModel.getHistoryLog(1,999,res=>{
				state.show.histroy = true
				state.histroyList = res.list
			})
		}

		return{
			...toRefs(state),
			checkUpdate,
			systemUpdate,
			setSysUpdate,
			getSysUpdateLog,
			celSysUpdate,
			checkSite,
			siteAuth,
			enter,
			scrollToBottom,
			goPage,
			getHistoryLog
		}
	}
	// updated: function() {
	// 	this.scrollToBottom();
	// }
}
</script>

<style lang="scss">
#systenUpdate {
	width: 100%;

	.update_data {
		width: 100%;

		&_title {
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 1px;
			margin-bottom: 20px;
		}
		&_desc {
			width: 60%;
			padding: 20px;
			background: #fafafa;
			// line-height: 30px;

			> div:first-child {
				font-size: 18px;
				letter-spacing: 1px;
				font-weight: bold;
				margin-bottom: 10px;
			}
			> div:last-child {
				font-size: 15px;
			}
		}

		.update_data_desc {
			height: 500px;
			// border: 1px solid;
			overflow: hidden;
			overflow-y: auto;
			p {
				margin: 0;
				font-size: 14px;
				color: #666;
			}
			br {
				height: 2px;
			}
		}
	}

	//
	.updatEdition {
		width: 100%;
		margin: auto;
		text-align: center;

		> div:nth-child(2) {
			margin-bottom: 10px;
		}

		> div:nth-child(3) {
			color: #999;
		}
        > div:nth-child(4) {
            margin-bottom: 40px;
            color: #999;
        }

		> div:nth-child(5) {
			margin-bottom: 40px;
		}
	}

	.updatelist {
		// width: 100%;

		&_title {
			width: 60%;
			font-size: 22px;
			font-weight: bold;
			letter-spacing: 1px;
			margin-bottom: 50px;
		}

		&_progress {
			width: 45%;
			margin-bottom: 20px;
		}

		&_list {
			width: 42.5%;
			background: #fafafa;
			padding: 20px;
			margin-bottom: 20px;

			&_title {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 15px;
			}

			&_cont {
				font-size: 13px;
				line-height: 28px;
				color: #999;
				width: 100%;
				height: 260px;
				overflow-y: auto;
				overflow-x: hidden;

				> div > div:last-child {
					margin-left: 10px;
				}
			}
		}
	}

	.updatesuscss {
		text-align: center;
		padding: 80px 20px;

		.suscss_title {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			line-height: 60px;
			display: flex;
			justify-content: center;
			background: #51b051;
			color: #fff;
			margin: auto;
		}

		.suscss_tips {
			padding: 30px 0 15px;
			font-weight: bold;
			font-size: 19px;
		}

		.suscss_desc {
			margin-bottom: 20px;
			color: #999;
		}

		.suscss_suscsstips {
			margin-bottom: 50px;
			color: #999;
		}
	}

	.history_data_desc {
		max-height: 500px;
		overflow: hidden;
		overflow-y: auto;
		p {
			margin: 0;
			font-size: 14px;
			color: #666;
		}
		br {
			height: 2px;
		}
	}
}
</style>
